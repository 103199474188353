<template>
  <div class="content">
    <OfferNannyInputPhotoMain
      v-model:imgCroppedSrc="offerNanny.details.photoMain.src"
      @update:imgCroppedFile="offerNanny.details.photoMain.file = $event"
      v-model:imgInputSrc="offerNanny.details.photoMain.originalSrc"
      @update:imgInputFile="offerNanny.details.photoMain.originalFile = $event"
      v-model:imgInputType="offerNanny.details.photoMain.originalMimetype"
      v-model:imgInputName="offerNanny.details.photoMain.originalFilename"
      @update:is-edit="isEdit = $event"
    />

    <ButtonBasicNext
      v-show="!isEdit"
      :to="{ name: ROUTE_OFFER_NANNY_CREATE_FINISH_NAME }"
    />
  </div>
</template>

<script>
import { reactive, ref } from "@vue/reactivity";
import { useOfferNannyStore } from "@/stores/offerNanny";
import { onMounted, onUnmounted, watch } from "vue";
import { ROUTE_OFFER_NANNY_CREATE_FINISH_NAME } from "@/router/constants";

import OfferNannyInputPhotoMain from "@/components/Offer/Nanny/OfferNannyInputPhotoMain.vue";
import ButtonBasicNext from "@/components/UI/ButtonBasicNext.vue";

export default {
  components: {
    OfferNannyInputPhotoMain,
    ButtonBasicNext,
  },

  setup() {
    const offerNanny = useOfferNannyStore();

    const isEdit = ref(false);

    const photoMainCache = reactive({
      ...offerNanny.details.photoMain,
    });

    const uploadPhotoMain = async () => {
      try {
        await offerNanny.updatePhotoMain();
      } catch (error) {
        console.log(error);
        alert(error.message);
      }
    };

    const cachePhotoMainFromStore = () => {
      Object.assign(photoMainCache, { ...offerNanny.details.photoMain });
    };

    const resetPhotoMainInStore = () => {
      console.log("resetPhotoMainInStore");
      offerNanny.details.photoMain.originalFile = null;
      offerNanny.details.photoMain.file = null;
      offerNanny.details.photoMain.originalFilename =
        photoMainCache.originalFilename;
      offerNanny.details.photoMain.originalMimetype =
        photoMainCache.originalMimetype;
      offerNanny.details.photoMain.originalSrc = photoMainCache.originalSrc;
    };

    watch(
      () => offerNanny.details.photoMain.file,
      (photoMainCroppedFile) => {
        if (!photoMainCroppedFile) {
          return;
        }
        uploadPhotoMain();
        cachePhotoMainFromStore();
      }
    );

    onMounted(() => {
      window.addEventListener("unload", () => handleUnload());
    });

    onUnmounted(() => {
      window.removeEventListener("unload", () => handleUnload());
    });

    const handleUnload = () => {
      alert("handleUnload");
      if (isEdit.value === true) {
        resetPhotoMainInStore();
      }
    };

    return {
      offerNanny,
      isEdit,
      ROUTE_OFFER_NANNY_CREATE_FINISH_NAME,
    };
  },
};
</script>

<style scoped>
.content {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 60px;
}

@media (max-width: 1200px) {
}
</style>
